<template>
    <div style="text-align: left;" class="radar">
        <el-tabs v-model="current" class="">
            <el-tab-pane :label="v.name" :name="v.name" v-for="(v,i) of list" :key="i"></el-tab-pane>
        </el-tabs>
        <template v-if="current=='雷达'">
            <div class="boxs" v-if="radarObject && radarObject.content && radarObject.content.length">
                <div class="box" v-for="(v,i) of radarObject.content" :key="i" @click="v.operationUserName && v.operationUserName!='游客' ? goto('/enter/radarDetail?userId='+v.operationUserId+'&moduleId='+enterpriseId+'&type=1') : tourist()">
                    <div class="item">
                        <div class="top">
                            <div class="top_left">
                                <img width="45px" height="45px" class="circle" :src="$oucy.ossUrl +v.operationUserAvata" v-if="v.operationUserAvata"></img>
                                <img width="45px" height="45px" class="circle" src="https://aliyunoss.lesoujia.com/lsj_mobile/image/Live/visitor.png" v-else></img>
                            </div>
                            <div class="top_right">
                                <div class="top_right_top">
                                    <div class="name f15 bold c-1 line-1">{{v.operationUserName||'游客'}}</div>
                                </div>
                                <div class="m-t-5 f12 c_c0">来自{{v.radarRecordOriginName || '平台' }}分享的名片</div>
                            </div>
                        </div>
                        <div class="bottom c_333 f12">
                            <el-button type="" size="mini" v-if="v.isFriend" @click.stop="">已是好友</el-button>
                            <el-button type="primary" size="mini" @click.stop="AddFItem(v,i)" v-else>申请好友</el-button>
                            <el-button type="" size="mini" @click.stop="Consult(v,i)">发消息</el-button>
                            <el-button type="" size="mini" @click.stop="makePhoneCall(v,i)">查看电话</el-button>
                        </div>
                        <div class="center line-1 f12">
                            <div class="line-1">
                                <span v-if="v.clueCount"> 第 <span class="c_2090ff">{{v.clueCount}} </span> 次 </span>
                                <span class="c_2090ff">
                                    {{types[v.radarRecordEnterpriseDoType]}}:
                                </span>
                                <span class="c_c0 ">{{v.radarRecordObjectName}}</span>
                            </div>
                            <div class="c_c0 line-1" v-if="v.record">：{{v.record}}</div>
                        </div>
                        <div class="date f14 c_c0">
                            记录时间：{{timestampFormat(v.operationTime)}}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="text-center content_no c-2 f14">
                <!-- <img :src="nostaff" mode="widthFix" class="content_no"> -->
                <div>- 当前还未有雷达信息记录 -</div>
            </div>
            <el-pagination v-if="radarObject && radarObject.content && radarObject.content.length" class="m-t-10" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="radarObject.size" :total="radarObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange" :page-sizes="[12,36,60,120]">
            </el-pagination>
        </template>
        <template v-if="current=='线索'">
            <div class="boxs" v-if="threadObject && threadObject.content && threadObject.content.length">
                <div class="box" v-for="(v,i) of threadObject.content" :key="i" @click="v.operationUserName ? goto( '/enter/radarDetail?userId='+v.operationUserId+'&moduleId='+enterpriseId+'&type=2') : tourist()">
                    <div class="item">
                        <div class="top">
                            <div class="top_left">
                                <img width="45px" height="45px" class="circle" :src="$oucy.ossUrl +v.operationUserAvata"></img>
                            </div>
                            <div class="top_right">
                                <div class="top_right_top">
                                    <div class="name f15 bold c-1 line-1">{{v.operationUserName}}</div>
                                    <div class="f14 c_c0">
                                        <el-button type="success" size="mini" v-if="v.operationUserIsRealName">已实名</el-button>
                                        <el-button type="" size="mini" v-else>未实名</el-button>
                                </div>
                                </div>
                                <div class="m-t-5 f12 line-1 c_c0">推荐人：{{v.operationUserRecommendUserNickName||'平台'}}</div>
                            </div>
                        </div>
                        <div class="center f12">
                            <div class="c_2090ff">访问次数：
                                <span class="c_c0">{{v.clueCount}}</span>
                            </div>
                        </div>
                        <div class="bottom c_333 f12">
                            <el-button type="" size="mini" v-if="v.isFriend" @click.stop="">已是好友</el-button>
                            <el-button type="primary" size="mini" @click.stop="AddFItem(v,i)" v-else>申请好友</el-button>
                            <el-button type="" size="mini" @click.stop="Consult(v,i)">发消息</el-button>
                            <el-button type="" size="mini" @click.stop="makePhoneCall(v,i)">查看电话</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="text-center content_no c-2 f14">
                <!-- <img :src="nostaff" mode="widthFix" class="content_no"> -->
                <div>- 当前还未有线索信息记录 -</div>
            </div>
            <el-pagination v-if="threadObject && threadObject.content && threadObject.content.length" class="m-t-10" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="threadObject.size" :total="threadObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange" :page-sizes="[12,36,60,120]">
            </el-pagination>
        </template>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { elk,friend } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "index",
    components: {},
    data() {
        return {

            // statusBarHeight: getApp().globalData.statusBarHeight,
            serverImg: this.$oucy.serverImg + 'radar/',
            menuList: [{
                name: '会话列表'
            }, {
                name: '好友列表'
            }],
            current: '雷达',
            swiperCurrent: 0,
            unreadCount: 0,
            showGroup: false,
            showFirend: false,
            delTrue: 0, //删除好友成功就有变化，用于监听
            friend: null,
            reFriend: 0, //每次打开都重新获取好友列表也用来清除从聊天返回后显示的未读条数
            keywords: null,
            newFriend: false,
            linkUrl: "/module/AboutMe/SearchFriends/SearchFriends?keywords=",
            nostaff: this.$oucy.serverImg + 'card/nostaff.png',
            globalData: null,
            list: [{
                    name: '雷达'
                },
                {
                    name: '线索'
                }
            ],

            UserRecordGroupList: [],
            hasMore2: false,
            start2: 0,

            UserRecordEventList: [],
            hasMore: false,
            start: 0,
            showUserMenu: false,
            enterpriseId: null,
            userInfo: {},
            showAdd: 0,
            // 0pc端：web浏览器、 1手机端：微信公众平台、 2手机端：小程序-1、3手机端：小程序-2、 4手机端：安卓、 5手机端：苹果
            formType: {
                "0": 'web浏览器',
                "1": '微信公众平台',
                "2": '小程序-1',
                "3": '小程序-2',
                "4": '安卓',
                "5": '苹果'
            },
            types: {
                "0": "查看你的企业名片，请尽快把握商机",
                "1": "查询员工列表",
                "2": "查看你的员工名片",
                "3": "查询短视频列表",
                "4": "查看你的短视频",
                "5": "查看你的商品列表",
                "6": "查了你的商品",
                "7": "点赞你的企业名片",
                "8": "点赞员工名片",
                "9": "点赞短视频",
                "10": "取消点赞你的企业名片",
                "11": "取消点赞员工名片",
                "12": "取消点赞短视频",
                "13": "收藏企业名片",
                "14": "点赞员工名片",
                "15": "点赞短视频",
                "16": "收藏商品",
                "17": "评论你的企业名片",
                "18": "评论员工名片",
                "19": "评论短视频",
            },
            group: {},
            // 请求对象
            radarQuery: {
                limit: 12,
                start: 0,
            },
            radarObject: {},
            // 请求对象
            threadQuery: {
                limit: 12,
                start: 0,
            },
            threadObject: {},

        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.radarQuery.enterpriseId = this.enterpriseId
        this.threadQuery.enterpriseId = this.enterpriseId
        this.queryRadar()
        this.queryThread()
    },
    methods: {
        // 切换页数
        sizeChange(pageSize) {
            if (this.current == '雷达') {
                this.radarQuery.limit = pageSize;
                this.queryRadar();
            } else {
                this.threadQuery.limit = pageSize;
                this.queryThread()
            }
        },
        // 切换页码
        currentChange(current) {
            if (this.current == '雷达') {
                this.radarQuery.start = current - 1;
                this.queryRadar();
            } else {
                this.threadQuery.start = current - 1;
                this.queryThread()
            }
        },

        tourist() {
            this.$message('游客无详细记录')
        },

        // 雷达
        queryRadar() {

            elk.queryRadar(this.radarQuery).then(res => {
                console.log('queryRadar', res)
                this.radarObject = res
            }, err => {
                console.log(err)
            })
        },
        // 线索
        queryThread() {
            elk.queryThread(this.threadQuery).then(res => {
                this.threadObject = res
            }, err => {
                console.log(err)
            })
        },



        // 私信
        Consult(v) {
           
            this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, { targetUserId: v.operationUserId });
        },
        // 添加好友
        AddFItem(v) {
            friend.addFriend({ targetUserId: v.operationUserId }).then(res => {
                // this.dialogVisible=false
                this.$message('操作成功')
            })
        },
        // 打电话
        makePhoneCall(e) {
            let phone = e.operationUserPhone
            if (phone) {
                this.$alert(phone, '查看电话')

            } else {
                this.$message('当前用户没有绑定电话')

            }
        },
        // 格式化时间
        timestampFormat(dateTimeStamp) {
            if (!dateTimeStamp) return
            let timestamp = new Date(dateTimeStamp.replace(/-/g, '/')).getTime() / 1000

            function zeroize(num) {
                return (String(num).length == 1 ? '0' : '') + num;
            }

            let curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
            let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

            let curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
            let tmDate = new Date(timestamp * 1000); // 参数时间戳转换成的日期对象

            let Y = tmDate.getFullYear(),
                m = tmDate.getMonth() + 1,
                d = tmDate.getDate();
            let H = tmDate.getHours(),
                i = tmDate.getMinutes(),
                s = tmDate.getSeconds();

            if (timestampDiff < 60) { // 一分钟以内
                return "刚刚";
            } else if (timestampDiff < 3600) { // 一小时前之内
                return Math.floor(timestampDiff / 60) + "分钟前";
            } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
                return '今天' + ' ' + zeroize(H) + ':' + zeroize(i);
            } else {
                let newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
                if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
                    return '昨天' + ' ' + zeroize(H) + ':' + zeroize(i);
                } else
                    // 和微信一样4天内的为周
                    if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() < d * 1 + 4) {
                        let w = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][tmDate.getDay()]
                        return w + ' ' + zeroize(H) + ':' + zeroize(i);
                    }
                /*else if ( curDate.getFullYear() == Y ) { // 年内
                            return  zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                        } */
                else {
                    return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                }
            }
        },
        goto(path) {
            this.$oucy.go(path)
        }

    }

}
</script>
<style scoped lang="less">
    .radar{
        background: #fff;
        padding-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        min-height: 700px;
    }
.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxs {
    display: flex;
    /*padding: 15px 20px 150px 20px;*/
    flex-wrap: wrap;
    margin:0 -10px -10px -10px;
}

.box {
    width: 25%;   
    cursor: pointer;
}
@media screen and (max-width: 1500px) {
    .box {
        width: 33.3333333%;
    }
}
.item{
   border-radius: 10px;
   padding: 10px;
   background: #F5F8FF;
   margin: 10px;
   box-sizing: border-box;
   /*margin-bottom: 15px;*/
   &:hover{
   box-shadow: 0px 3px 20px rgba(51, 51, 51, 0.1); 
   background: #FFFFFF;

   }
}

.top {
    display: flex;
    /*padding: 10px;*/
}

.top_right {
    // display: flex;
    padding-left: 15px;
    flex: 1;
}

.top_right_top {
    display: flex;
    justify-content: space-between;

}

.date {
    font-size: 12px;
    white-space: nowrap;
    color: #AAAFB7;
    margin-top: 10px;
}

.center {
    display: flex;
    margin-top: 10px;
}

.bottom {
    display: flex;
    /*justify-content: space-between;*/
    border-top: 1rpx solid #EFEFEF;
    margin-top: 10px;
}

.bottom_item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
}

.bottom_item+.bottom_item {
    /*border-left: 1px solid #EFEFEF;*/

}

.content_no {
    margin: 200px auto;

}

.name {
    word-wrap: break-word;
    max-width: 140px;
}

.circle {
    border-radius: 100px;
}

.c_c0 {
    color: #AAAFB7;
}

.c_2090ff {
    color: #2090ff;
}
</style>